.button-link
{
    text-decoration: none;
    font-size: 18px;
    background-color: #bc2224;
    padding: 15px 25px;
    color: white;
    border-radius: 5px;

}
.button-link:hover
{
    background-color: #fff;
    color: #bc2224;
    border: 1px solid #bc2224;
}