.topbar-box
{
    background-color: #bc2224;
    color: #fff;
    position: sticky;
    top: 0;
    z-index: 9;
}
.topbar-box a
{
    color: #fff;
    text-decoration: none;
}
.tobar-content
{
    padding: 10px 0;
}
.tobar-content div
{
display: inline-block;
}
.topbar-call
{
margin-right: 25px;
}
.topbar-social ul
{
    margin: 0;
    list-style: none;
}
.topbar-social ul li
{
    display: inline-block;
    margin: 0 10px;
    font-size: 22px;
}