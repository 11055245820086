footer
{
    background-color: black;
    padding: 25px 0;
    color: white;
}
.footerarea h3, .footerarea h4
{
    color:#bc2224;
}
.footerarea a
{
    color: #5597ce;
    text-decoration: none;
    word-break: break-word;
}
.firstcolumn img
{
margin-bottom: 20px;
}
.fourthcolumn
{
    font-size: 18px;
}
.fourthcolumn ul
{
    margin: 0;
    list-style: none;
}
.fourthcolumn ul li
{
    display: inline-block;
    margin: 0 10px;
    font-size: 35px;
}

.copyright-conent
{
    text-align: center;
    margin-top: 50px;
}
.copyright-conent p
{
    font-size: 12px;
}
@media (min-width: 992px){ 
    .accepted-card img
{
width: auto;
}
    }