.about-header
{
    background-image: url('../../../public/images/about-hero-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.about-header h1
{
font-size: 90px;
line-height: 90px;
}
.about-header
{
    padding: 90px 0;
}
.about-link
{
    display: inline-block;
    margin-top: 30px;
}
.about-certification
{
    padding: 30px 0;
}