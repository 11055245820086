.header
{
    background-image: url('../../public/images/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.header h1
{
font-size: 120px;
line-height: 120px;
}
.header-content
{
    padding: 20px 0 0 0;
}
.herobutton
{
    
    margin-top: 50px;
    margin-bottom: 50px;
    display: inline-block;
}
@media (max-width: 981px) {
    .header h1
    {
        font-size: 70px;
        line-height: 70px;
    }

}
@media (max-width: 767px) {
    .header h1
    {
        font-size: 50px;
        line-height: 50px;
    }

}