.contact-area
{
    padding: 25px 0;
}
.btn-danger
{
    background-color: #bc2224;
    padding: 10px 25px;
    font-size: 25px;
    font-weight: normal;
}
.btn-danger:hover
{
    background-color: #fff;
    color: #bc2224;
    border: 1px solid #bc2224;
}
.contact-info p
{
font-size: 18px;
}
.contact-info p a
{
text-decoration: none;
color: #5597ce;
}
.contact-info p svg
{
    margin-right: 15px;
}
.successMessage
{
    font-size: 25px;
    font-weight: bold;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }