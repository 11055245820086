.navbar
{
    background-color: white ;
    position: sticky ;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    top: 46.4px;
    z-index: 9;
}
.navbar-nav>a.nav-link
{
    margin-left: 10px;
    margin-right: 10px;
}
.logo-area img
{
    width: 180px;
    padding: 10px 0;
}
#navbarResponsive
{
    justify-content: right;
}
.navbar-nav li.active a
{
    color:#bc2224;
}
.navbar-nav li a
{
    color:#646464;
}
.dropdown-menu li a
{
    padding: 0 40px;
}
.dropdown-menu li
{
    padding: 0 10px;
}
.dropdown-menu
{
    background-color: #f8f9fa;
}
.dropdown-menu li a:hover
{
    background-color: #bc2224;
    color: #fff;

}
.custom-dropdown
{
    list-style: none;
}


@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    position: relative;
}
.column
{
    list-style: none;
    width: 48%;
}
.column li a{
    padding-left:0 ;
}
.first-column
{
    float: left;
}
.second-column
{
    float: right;
}
}
@media only screen and (max-width: 1199px) and (min-width: 494px)  {
    .navbar {
        top: 67.4px;
    }
}
@media (max-width: 494px){
    .navbar {
        top: 94.4px;
    }
}