@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&family=Roboto&display=swap');

body
{
  font-family: 'Roboto', sans-serif;
}
h1,h2,h3,h4
{
  font-family: 'Kanit', sans-serif;
  margin: 20px 0 15px 0;
}
h2
{
  font-size: 40px;
}
img
{
  width: 100%;
}
p
{
  line-height: 25px;
  font-size: 15px;
}
ul li{
  line-height: 25px;
  font-size: 15px;
}
.nav-link
{
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}
.dropdown
{
  position: unset;
}
.single-service
{
  padding-bottom: 50px;
}
.inner-page-header
{
  padding: 25px 0;
}
.trustpilot-widget
{
  height: 50px !important;
}
@media (min-width: 992px){ 
.navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 30px;
    left: auto;
    right: 0;
}
}
@media (max-width: 767px){ 
  .inner-page-header .text-end {
    text-align: left !important;
    margin-top: 25px;
  }
  }