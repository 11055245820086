.support-section
{
    padding: 25px 0;
}
.services-list-area
{
    background-color: #5597ce;
    color: white;
    padding: 25px 0;
}
.services-list ul
{
    list-style-type: disc;
}
.services-list ul li
{
    display: inline-block;
    width: 33%;
    margin: 5px 0;
}
.services-list ul li svg
{
margin-right: 10px;
}
.providing-services
{
    padding: 25px 0; 
}
.partner-logo
{
    background-color: #4f8abf;
}
.logo-list
{
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 25px 0;
    flex-wrap: wrap;
}
.logo-list li
{
    padding: 15px 30px;
}
.logo-list li img
{
    width: 250px;
}
@media (max-width: 768px) {
    .services-list ul li
    {
        width: 50%;
    }

}
@media (max-width: 480px) {
    .services-list ul li
    {
        width: 100%;
    }

}